import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {gameCancel, gameStart, gameResult, gameInfo, gameIsActive} from "@actions/gameControl";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Space, Row, Col, Switch} from "antd";
import CardsList from "@components/CardsList/CardsList";
import {scanCard} from "@actions/cardAction";
import Timer from "@components/Timer/Timer";
import classNames from "classnames";
import {winnerTypes, playerTypes} from "@constants/Enums";
import CustomSelect from "@components/CustomSelect/customSelect";
import Settings from "@components/Settings";
import { TurnTypes } from "../../../constants/Enums";

function GameControl({
                         gameCancel,
                         gameStart,
                         gameResult,
                         gameInfo,
                         loading,
                         cards,
                         scanCard,
                         timer,
                         winner,
                         gameIsActive,
                         isActive,
                         turn,
                         roundId,
                         intl
                     }) {
    const [online, setOnline] = useState(navigator.onLine);
    const timeOut = useRef([])

    useEffect(() => {
        function enable(e) {
            setOnline(true)
        }

        function disable(e) {
            setOnline(false)
        }

        window.addEventListener('online', enable);
        window.addEventListener('offline', disable);

        return () => {
            window.removeEventListener('online', enable);
            window.removeEventListener('offline', disable);
        }
    }, [])

    useEffect(() => {
        if (online) {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
            gameInfo()
        }
    }, [online])

    useEffect(() => {
        if (!loading._game_info && online) {
            timeOut.current.push(setInterval(() => {
                gameInfo()
            }, 1000))
        }
        return () => {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
        }
    }, [loading._game_info])


    const autoFocus = () => {
        window.focus();
        if (document.activeElement) {
            document.activeElement.blur();
        }
    }
    return (<div>
        <div className="titleArea">
            <div className="titleArea--title"><FormattedMessage id='game_control'/></div>
            <div className="titleArea--BtnGroup">
                <Settings />
                <div className="game-show-switch">
                    <span><FormattedMessage id="enable_game" /></span>
                    <Switch loading={loading._game_is_active}
                            onClick={() => gameIsActive(!isActive)}
                            disabled={turn}
                            checked={isActive}/>
                </div>

            </div>
        </div>
        <div className="game-control">
            {isActive ?
                <>
                    <Row className="game-control-row" justify="space-between" align="center">
                        <Col span={10} className={classNames("game-control-col", {active: turn === TurnTypes.player})}>
                            {
                                winner === winnerTypes.player || winner === winnerTypes.tie ?
                                    <h2 className={classNames("winner", {draw: winner === winnerTypes.tie})}>
                                        <FormattedMessage id={winner === winnerTypes.tie ? "tie" : "winner"}/>
                                    </h2> : ""
                            }
                            <div className="title">Player</div>
                            <CardsList cards={cards.playerCards} isActive={turn == TurnTypes.player} className="player-cards" />
                        </Col>
                        <div className="custom-control">
                            <Timer timer={timer}
                                   scanCard={scanCard}
                            />
                            {
                                turn?
                                    <CustomSelect autoFocus={autoFocus} disabledCustomSelect={!turn || loading._game_cancel}/>
                                    : ""
                            }

                        </div>
                        <Col span={10}
                             className={classNames("game-control-col banker-cards", {active: turn === TurnTypes.banker})}>
                            {
                                winner === winnerTypes.banker || winner === winnerTypes.tie?
                                    <h2 className={classNames("winner", {draw: winner === winnerTypes.tie})}>
                                        <FormattedMessage id={winner === winnerTypes.tie ? "tie" : "winner"}/>
                                    </h2> : ""
                            }

                            <div className="title">Banker</div>
                            <CardsList cards={cards.bankerCards} isActive={turn == TurnTypes.banker} className="banker-cards"/>
                        </Col>
                    </Row>
                    <Row justify="space-between" align="center">
                        <Col span={8} className="control-buttons">
                            <Space>
                                {
                                    <>
                                        {
                                            !roundId ?
                                                <Button loading={loading._game_start}
                                                        disabled={loading._game_result || loading._game_start || loading._game_cancel}
                                                        className="miniBtn"
                                                        size="large"
                                                        onClick={gameStart}>
                                                    <FormattedMessage id='start'/>
                                                </Button>
                                                : ""
                                        }
                                        {
                                            winner ?
                                                <Button loading={loading._game_result}
                                                        disabled={loading._game_result || loading._game_start || loading._game_cancel}
                                                        className="miniBtn"
                                                        size="large"
                                                        onClick={gameResult}>
                                                    <FormattedMessage id='result'/>
                                                </Button>
                                                : ""
                                        }

                                    </>
                                }

                            </Space>
                        </Col>
                        <Col span={8} className="control-buttons">
                            <Space>
                                {
                                    !roundId  ? "" :
                                        <Button loading={loading._game_cancel}
                                                disabled={loading._game_result || loading._game_start || loading._game_cancel || !roundId}
                                                className="miniBtn"
                                                size="large"
                                                onClick={gameCancel}>
                                            <FormattedMessage id='cancel'/>
                                        </Button>
                                }

                            </Space>
                        </Col>
                    </Row>
                </> :
                <div className="message">
                    <FormattedMessage id="table_is_not_active"/>
                </div>}


        </div>


    </div>)
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        cards: state.cards,
        timer: state.timer,
        winner: state.winner,
        isActive: state.gameIsActive,
        turn: state.turn,
        roundId: state.roundId
    }
}

const mapDispatchToProps = {
    gameCancel,
    gameStart,
    gameResult,
    scanCard,
    gameInfo,
    gameIsActive
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GameControl))